//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap base colors
$white: #ffffff;
$black: #000000;
$gray-100: if(isDarkMode(), #1b1b29, #f5f8fa);
$gray-200: if(isDarkMode(), #2b2b40, #eff2f5);
$gray-300: if(isDarkMode(), #323248, #e4e6ef);
$gray-400: if(isDarkMode(), #474761, #b5b5c3);
$gray-500: if(isDarkMode(), #565674, #a1a5b7);
$gray-600: if(isDarkMode(), #6d6d80, #9497a3);
$gray-700: if(isDarkMode(), #92929f, #5e6278);
$gray-800: if(isDarkMode(), #cdcdde, #3f4254);
$gray-900: if(isDarkMode(), #ffffff, #181c32);
$text-muted: $gray-500;

// Dark Mode
$dark-mode-page-bg-color: #151521;

// Bootstrap gray colors map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

// Theme colors
// Primary
$primary: if(isDarkMode(), #3699ff, #14b8a6);
$primary-bold: if(isDarkMode(), #3699ff, #0d9488);
$primary-active: if(isDarkMode(), #187de4, #f0fdfa);
$primary-light: if(isDarkMode(), #212e48, #f0fdfa);
$primary-inverse: #ffffff;

// Secondary
$secondary: $gray-300;
$secondary-active: $gray-700;
$secondary-light: $gray-100;
$secondary-inverse: $gray-800;

// Blue
$blue: if(isDarkMode(), #3699ff, #009ef7);
$blue-active: if(isDarkMode(), #187de4, #f1faff);
$blue-light: if(isDarkMode(), #212e48, #f1faff);
$blue-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #0bb783, #50cd89);
$success-active: if(isDarkMode(), #04aa77, #47be7d);
$success-light: if(isDarkMode(), #1c3238, #e8fff3);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #8950fc, #8700f1);
$info-active: if(isDarkMode(), #7337ee, #5014d0);
$info-light: if(isDarkMode(), #2f264f, #f5e8ff);
$info-inverse: #ffffff;

// Light colors
$light: if(isDarkMode(), $gray-300, $gray-100);
$light-active: if(isDarkMode(), $gray-400, #14b8a6);
$light-inverse: if(isDarkMode(), $gray-800, $gray-600);
$lighten: rgba($light, 0.5);

// Danger
$danger: if(isDarkMode(), #f64e60, #f1416c);
$danger-active: if(isDarkMode(), #ee2d41, #ffd0db);
$danger-light: if(isDarkMode(), #3a2434, #fff5f8);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #ffc700);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

// Border Radiues
$border-radius-sm: 0.3rem;
$border-radius: 0.65rem;
$border-radius-lg: 0.85rem;
$border-radius-xl: 1.25rem;

// Card Box Shadow
$card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);

$form-feedback-valid-color: $primary;

// Status
$order-status-open: #009ef7;
$bg-order-status-open: #f1faff;
$order-status-shipped: #47be7d;
$bg-order-status-shipped: #e8fff3;
$order-status-awaiting-payment: #ff7272;
$bg-order-status-awaiting-payment: #ffecec;
$order-status-on-hold: #f1bc00;
$bg-order-status-on-hold: #fff8dd;
$order-status-cancelled: #ffecec;
$bg-order-status-cancelled: #ff7272;

$claims-status-unsubmitted: #47be7d;
$bg-claims-status-unsubmitted: #e8fff3;
$claims-status-in-progress: #009ef7;
$bg-claims-status-in-progress: #f1faff;
$claims-status-needs-more-info: #f1bc00;
$bg-claims-status-needs-more-info: #fff8dd;
$claims-status-approved: #f1faff;
$bg-claims-status-approved: #009ef7;
$claims-status-denied: #ffecec;
$bg-claims-status-denied: #ff7272;
$claims-status-appealed: #ff7272;
$bg-claims-status-appealed: #ffecec;
$claims-reason-lost: #ff7272;
$claims-reason-damage: #a1a5b7;
$claims-reason-missing-content: #eba234;
$claims-reason-delivered-incorrect-address: #3446eb;
$bg-tooltip-error: #fee7e8;

$user-status-active: #009ef7;
$bg-user-status-active: #f1faff;
$user-status-inactive: #ff7272;
$bg-user-status-inactive: #ffecec;
$user-status-pending: #14b8a6;
$bg-user-status-pending: #f0fdfa;
$shipment-tracking: #7e1eca;

//Gori's Color
$gori-color-a: #34c8b5;
$gori-color-b: #13a7a5;
$gori-color-c: #3e6dad;
$gori-color-d: #4030a0;
$gori-gradient: linear-gradient(
  to right,
  $gori-color-a,
  $gori-color-b,
  $gori-color-c,
  $gori-color-d
);
$gori-orange: #f1bc00;
